<template>
  <ul>
    <component
      :is='resolveNavItemComponent(item)'
      v-for='item in accessibleRoutes'
      :key='item.header || item.title'
      :item='item'
    />
  </ul>
</template>

<script>
import { resolveNavItemShow, resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    userRoles() {
      let item = localStorage.getItem('userData');
      let parsedUserData = JSON.parse(item);
      return parsedUserData.roles;
    },
    accessibleRoutes(){
      return this.items.filter(route => {
        if (!route.meta || !route.meta.authorities) return true;
        return route.meta.authorities.some(role => this.userRoles.some(userRole=>userRole.name===role));
      });
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent
    }
  }
}
</script>
